<template>
    <div class="d-flex justify-content-between w-100 align-items-center display-name" @click="sortTable()" @touchend="sortTable()">
        <div class="add-ellipsis-production-p-pcp">
            <span :id="`tooltip-${params.displayName}`">
              {{ $t(params.displayName) }}
            </span>
        </div>
        <b-tooltip
          custom-class="tooltip-production-p-pcp"
          :target="`tooltip-${params.displayName}`"
          triggers="hover blur"
          positioning="top"
          placement="top"
          boundary-padding="0"
        >
          <div class="info-tooltip-production-p-pcp">
            {{ $t(params.displayName) }}
          </div>
        </b-tooltip>
        <div class="sortable-icons" v-if="params.column.colDef.sortable">
            <asc-icon class="icon-opacity" :class="{ 'active': params.column.colId === fieldSort && sort == 'asc' }" />
            <desc-icon class="icon-opacity" :class="{ 'active': params.column.colId === fieldSort && sort == 'desc' }" />
        </div>
    </div>
</template>

<script>
import {
    BTooltip,
} from 'bootstrap-vue'

export default {
    components: {
        ascIcon: () => import('@/assets/images/icons/sort-asc.svg'),
        descIcon: () => import('@/assets/images/icons/sort-desc.svg'),
        BTooltip
    },
    mounted() {
        let parentContext = this.params.parentContext;

        if (parentContext && parentContext.filter) {
            this.filter = parentContext.filter;
            this.sort = parentContext.filter.sort;
            this.fieldSort = parentContext.filter.fieldSort;
        }
    },
    watch: {
        'filter.fieldSort'(value) {
            this.fieldSort = value;
        },
        'filter.sort'(value) {
            this.sort = value;
        },
    },
    data() {
        return {
            sort: '',
            fieldSort: '',
            filter: null
        }
    },
    methods: {
        sortTable() {
            if (this.params.column.colDef.sortable && this.params.parentContext && this.params.parentContext.filter) {
                this.params.parentContext.filter.fieldSort = this.params.column.colId;
                this.params.parentContext.filter.sort = this.sort == 'asc' ? 'desc' : 'asc';
                this.sort = this.params.parentContext.filter.sort;
                this.fieldSort = this.params.column.colId;
            }

            $('[data-toggle="tooltip"]').tooltip('hide');
        }
    },
};
</script>

<style lang="scss" scoped>
.sortable-icons {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.active {
    opacity: 1 !important;
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(228deg) brightness(65%) contrast(107%) !important;
}

.icon-opacity {
    opacity: 0.4;
}
</style>
