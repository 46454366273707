import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    template: `<div class="dropdown d-action-orders">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg"  width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-body align-middle mr-25 feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </button>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item d-flex align-items-start" @click="redirectPage('production-progress')">
                            <svg width="16" height="16" fill="none" style="
                            overflow: visible !important" xmlns="http://www.w3.org/2000/svg"><path d="M14.667 1.333v13.334H1.333V1.333h13.334zM16 0H0v16h16V0zm-2.667 4.667H8v.666h5.333v-.666zm0 3.333H8v.667h5.333V8zm0 3.333H8V12h5.333v-.667zm-7.01-7.536l-.474-.464-1.702 1.738-1.026-.968-.465.474L4.156 6l2.167-2.203zm0 3.334l-.474-.464-1.701 1.738-1.026-.968-.465.472 1.499 1.424 2.167-2.202zm0 3.333L5.849 10l-1.701 1.738-1.026-.968-.465.473 1.499 1.424 2.167-2.203z" fill="#998F8A"></path></svg>
                            <span class="text-action-orders">
                                {{ $t('ordersList.ProductionProgress') }}
                            </span>
                        </a>

                        <a class="dropdown-item d-flex align-items-start" @click="redirectPage('order-details')">
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.333A6.674 6.674 0 0114.667 8 6.674 6.674 0 018 14.667 6.674 6.674 0 011.333 8 6.674 6.674 0 018 1.333zM8 0a8 8 0 100 16A8 8 0 008 0zm.667 12H7.333V6.667h1.334V12zM8 3.833a.834.834 0 110 1.668.834.834 0 010-1.668z" fill="#998F8A"></path></svg>
                            <span class="text-action-orders">
                                {{ $t('ordersList.Information') }}
                            </span>
                        </a>

                        <a class="dropdown-item d-flex align-items-start" href="javascript:void(0)" @click="deleteProduction">
                            <svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.666 16H2.333A1.334 1.334 0 011 14.667V3.333H.333V2h4V1c0-.551.449-1 1-1h3.333c.55 0 1 .447 1 1v1h4v1.333H13v11.334c0 .736-.598 1.333-1.334 1.333zm0-12.667H2.333v11c0 .184.15.334.333.334h8.667c.184 0 .333-.15.333-.334v-11zM5.666 6a.667.667 0 00-1.333 0v6a.667.667 0 001.333 0V6zm4 0a.667.667 0 00-1.333 0v6a.667.667 0 001.333 0V6zM8.333 1.333H5.666V2h2.667v-.667z" fill="#998F8A"></path></svg>
                            <span class="text-action-orders">
                                {{ $t('ordersList.DeleteProductionProgress') }}
                            </span>
                        </a>
                    </div>
                </div>`,
    components: {
        ToastificationContent
    },
    methods: {
        deleteProduction() {
            let currentSite = JSON.parse(localStorage.getItem('currentSite'));
            let prefix = localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix;
            let preactor_order_id = this.params.data.preactor_order_id;

            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('ordersList.DeleteText'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('IntegrationPage.delete'),
                customClass: {
                    container: 'swal-conection',
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`/api/${prefix}/operation-progress?preactor_order_id=${preactor_order_id}&site_guid=${currentSite.guid}`)
                        .then(() => {
                            this.showToast(this.$t('MySites.success'), 'SmileIcon', this.$t('ordersList.SuccessMessage'), 'success');
                            this.params.parentContext.getOrders();
                        })
                        .catch((error) => {
                            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
                        })
                }
            })
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            })
        },
        redirectPage(route) {
            let prefix = localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix;
            this.$router.push({ path: `/${prefix}/${route}/${this.params.data.preactor_order_id}/${this.params.data.order_no}/${this.params.data.op_no}` });
        }
    }
};
