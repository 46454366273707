import router from '@/router';

export default {
    template: `
    <button tabindex="-1" class="btn-action-production-progress" data-toggle="tooltip" :title="$t('ProductionList.ButtonOperatorMode')" data-trigger="hover" @click="() => {this.redirectOperatorMode(params.data.resource_id, params.data.preactor_order_id)}">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 6.5V11.5H0V2H6V3H1V10.5H9.5V6.5H10.5ZM12 0.5H6.506L8.5235 2.5L5.035 6.035L6.449 7.449L9.9375 3.914L12 6V0.5Z" fill="#974900"/>
        </svg>
    </button>
`,
    methods: {
        redirectOperatorMode(resource_id, preactor_order_id) {
            router.push({name: 'operator-production-progress', params: {resource_id: resource_id, order_id:preactor_order_id, return_orders:true, multiple:'false'}})
        },
    }
};
