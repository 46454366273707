<template>
    <div class="row custom-ag-grid-row">
        <ag-grid-vue class="ag-theme-alpine custom-ag-grid" ref="agGrid" :defaultColDef="defaultColDef" :rowHeight="58"
            :suppressRowHoverHighlight="true" :domLayout="'autoHeight'" :suppressNoRowsOverlay="true"
            :columnDefs="columnDefs" :rowData="rowData" :suppressColumnMoveAnimation="true"
            :suppressDragLeaveHidesColumns="true" :suppressColumnVirtualisation="true" :suppressRowVirtualisation="true"
            @columnMoved="(params) => $emit('changeColumn', params)"
            @columnResized="(params) => $emit('changeColumn', params)" @grid-ready="onGridReady">

        </ag-grid-vue>

        <div class="not-found" v-if="rowData.length == 0">
            <span>
                <SearchIcon />
                <h3>{{ $t('NoResultsFound') }}</h3>
            </span>
        </div>

        <div class="col-12 col-footer-table pagination-table">
            <div class="d-flex align-items-center mb-0 mt-1 por-pagina">
                <span class="text-nowrap">
                    {{ $t("message.pagelength") }}
                </span>
                <b-form-select v-model="pageSize" :options="['10', '20', '30']" class="mx-1" />
            </div>

            <b-pagination aria-controls="table-default" v-model="pageNumber" :total-rows="filter ? filter.totalItems : 0"
                :per-page="pageSize" class="mt-1 mb-0" :value="1" first-number last-number prev-class="prev-item"
                next-class="next-item">
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
            </b-pagination>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import CustomHeader from './CustomHeader.vue';
import CellAction from './CellAction.js';
import CellActionProductionProgress from './CellActionProductionProgress.js';

import {
    BPagination,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormInput,
    BFormGroup,
} from "bootstrap-vue";

export default {
    components: {
        AgGridVue,
        agColumnHeader: CustomHeader,
        CellAction,
        CellActionProductionProgress,
        SearchIcon: () => import('@/assets/images/pages/Search.svg'),
        BPagination,
        BFormSelect,
        BDropdownItem,
        BDropdown,
        BFormInput,
        BFormGroup,
    },
    props: {
        rowData: {
            type: Array,
            default: null,
        },
        columnDefs: {
            type: Array,
            default: null,
        },
        filter: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        if (this.filter) {
            this.pageSize = this.filter.pageSize;
            this.pageNumber = this.filter.pageNumber;
        }
    },
    created() {
        $(function () {
            const elements = document.getElementsByClassName("ag-body");

            for (const element of elements) {
                element.addEventListener("touchmove", closeTooltips)
            }

            function closeTooltips() {
                $('[data-toggle="tooltip"]').tooltip('hide');
            }
        })
    },
    data() {
        return {
            defaultColDef: {
                width: 170,
                minWidth: 70,
                sortable: true,
            },
            gridApi: null,
            pageSize: 0,
            pageNumber: 10
        };
    },
    watch: {
        pageNumber(value) {
            this.$emit('updatePageNumber', value);
        },
        pageSize(value) {
            this.$emit('updatepageSize', value);
        }
    },
    methods: {
        onGridReady(params) {
            this.$watch('rowData', () => {
                const rowCount = params.api.getDisplayedRowCount();
                const eGridBody = params.api.gridBodyCtrl.eGridBody;

                if (eGridBody) {
                    const eHorizontalScroll = eGridBody.querySelector('.ag-body-horizontal-scroll');
                    if (eHorizontalScroll) {
                        eHorizontalScroll.style.display = rowCount == 0 ? 'none' : 'flex';
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
@media only screen and (max-width: 480px) {

    .pagination-table {
        flex-direction: column;
        align-items: center;
    }

}

.pagination-table {
    display: flex;
    justify-content: space-between;
}

.custom-ag-grid-row {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.ag-dnd-ghost {
    display: none !important;
}

.custom-ag-grid {
    width: 100%;

    .ag-root-wrapper {
        border: none !important;
    }

    .ag-header {
        background-color: #faf6f2 !important;
        min-height: 38px !important;
        height: 38px !important;
        border-bottom: 0 !important;
        border-top: 1px solid #ebe9f1 !important;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4c4541 !important;
        outline: none;
        border-bottom: 0;

        .ag-header-row {
            height: 38px !important;
        }

        .ag-header-cell {
            padding: 0 10px;

            &.centered-header {
                .display-name {
                    justify-content: center !important;
                }
            }
        }

        .ag-header-cell-resize {
            &:hover {
                background-color: var(--ag-header-column-resize-handle-color);
            }
        }

        .ag-header-cell-resize::after {
            background-color: #faf6f2 !important;
            height: 100% !important;
            top: auto !important;


        }
    }

    .ag-row {
        border-bottom: 1px solid #E0E0E0 !important;
        color: #4c4541 !important;
    }

    .ag-cell {
        display: flex;
        align-items: center;
        padding: 0 10px;

        span:has(> div.badge) {
            width: 100%;
            text-align: center;
        }
    }

    div[col-id="actions"].ag-cell {
        overflow: visible;
    }

    .ag-cell-focus {
        border: none !important;
    }

    .ag-row-odd {
        background-color: #FAFAFA !important;
    }

    .ag-row-odd .ag-row-hover {
        background-color: #FAFAFA !important;
    }

    .ag-body {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .ag-center-cols-clipper {
        min-height: 0px !important;
    }

    .ag-cell-value {
        &.centered-cell {
            justify-content: center !important;
        }
    }

    .ag-row {
        z-index: 0;
    }

    .ag-row.ag-row-focus {
        z-index: 1;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #FBEEE8;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #CFC4BE;
    }

    /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    // }

    ::-webkit-scrollbar-corner {
        display: none;
    }
}

.pagination {
    .page-item.active {
        height: 33px;

        button {
            background-color: #974900;
        }
    }

    .page-item.disabled {
        .page-link {
            background-color: #faf6f2;
            color: #4c4541;
        }
    }
}

.col-footer-table {
    .por-pagina {
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4c4541;
        }
    }

    .custom-select {
        padding: 4px 34px 4px 8px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23974900' stroke='%23974900' stroke-width='0.5'/%3E%3C/svg%3E") !important;
    }
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 1px solid #CFC4BE;
    margin: 16px;
    border-radius: 5px;
    width: 100%;

    span {
        display: flex;
        align-items: baseline;

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #998F8A;
        }

        svg {
            margin-right: 16.13px;
        }
    }
}
</style>
